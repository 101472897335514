import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import MainBanner from "../components/Marketing/MainBanner"


const Index = () => {
    return (
        <Layout>
            <Navbar />
            <MainBanner />
            <Footer />
        </Layout>
    )
}

export default Index
